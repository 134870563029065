import React, { useState, useRef, useEffect } from "react";
import Image360 from "../../../images/icons/imagen-360.png";

export default function Visor360(props) {

    const moveMargin = 50;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [savedIndex, setSavedIndex] = useState(0);
    const [currentImage, setCurrentImage] = useState(props?.images ? props?.images[0] : props?.images[1]);
    const [isMoving, setIsMoving] = useState(false);
    const [currentX, setCurrentX] = useState(0);
    const [overlaySize, setOverlaySize] = useState({ width: 0, height: 0 });

    const mainRef = useRef();

    const handleTouchStart = (e) => {
        if (e.touches) {
        var t = e.touches[0] || e.changedTouches[0];
        setCurrentX(t.clientX);
        setIsMoving(true);
        }
    };

    const handleTouchMove = (e) => {
        if (isMoving) {
        e.preventDefault();
        var t = e.touches[0] || e.changedTouches[0];
        handleMove(t.pageX - mainRef.current.offsetLeft);
        }
    };

    const handleMouseDown = (e) => {
        setCurrentX(e.pageX - mainRef.current.offsetLeft);
        setIsMoving(true);
    };

    const handleMouseUp = (e) => {
        setIsMoving(false);
        setCurrentIndex(savedIndex);
    };

    const handleMove = (x) => {
        const w = mainRef.current.offsetWidth;

        if (x >= 0 && x < w) {
            const displacement = x - currentX;
            const steps = parseInt(displacement / moveMargin);
            let newIndex = currentIndex - steps;

            if (newIndex < 0) {
                while (newIndex < 0) newIndex += props.images.length;
            } else if (newIndex >= props.images.length) {
                while (newIndex >= props.images.length) newIndex -= props.images.length;
            }

            setSavedIndex(newIndex);
            setCurrentImage(props.images[newIndex]);
        }
    };

    const handleMouseMove = (evt) => {
        if (isMoving) {
            console.log("isMoving");
            handleMove(evt.pageX - mainRef.current.offsetLeft);
        }
    };

    const mainProps = {
        className: "w-100",
        style: {
        height: 400,
        backgroundImage: `url(${currentImage})`,
        backgroundSize: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        },
        onMouseDown: handleMouseDown,
        onMouseMove: handleMouseMove,
        onMouseUp: handleMouseUp,
        onTouchStart: handleTouchStart,
        onTouchMove: handleTouchMove,
        onTouchEnd: handleMouseUp,
    };

    useEffect(() => {
        function onResize(e) {
            if (mainRef && mainRef.current) {
                setOverlaySize({
                width: mainRef.current.offsetWidth,
                height: mainRef.current.offsetHeight,
                });
            }
        }

        window.addEventListener("resize", onResize);

        onResize();

        return () => {
        window.removeEventListener("resize", onResize);
        };
    }, []);

    return (
        <div ref={mainRef} {...mainProps}>
            {props.images.map((image, index) => {
                return (
                <img
                    src={image}
                    key={index}
                    alt="un carro"
                    onMouseMove={(e) => e.preventDefault()}
                    className="w-100 h-100 d-none"
                    style={{
                    backgroundImage: `url(${image})`,
                    position: "absolute",
                    border: "none",
                    position: 'relative'
                    }}
                    rol="img"
                    arial-label={props.alt}
                />
                );
            })}
            <div
                style={{
                position: "relative",
                width: overlaySize.width,
                height: overlaySize.height,
                cursor: "default",
                }}
                onMouseDown={(e) => e.preventDefault()}
            >
                <div
                className="w-100 text-white text-center"
                style={{
                    position: "absolute",
                    bottom: 0,
                }}
                >
                <img
                    src={Image360}
                    className="mr-2"
                    alt="un carro"
                    id="alguien"
                    style={{
                    width: 60,
                    }}
                    rol="img"
                    arial-label={props.alt}
                />
                <span
                    className="rounded"
                    style={{ backgroundColor: "rgba(0,0,0,0.5)", padding: 8 }}
                >
                    Arrasta hacia los lados para rotar el carro.
                </span>
                </div>
            </div>
        </div>
    );
}
